import React from "react";

//Images
import Vlanc_logo from "../../images/Vlanc_FinancX_LOGO.png";

export default function Footer() {
  return (
    <>
      <footer
        className="page-footer font-small blue"
        style={{
          height: "auto",
          background: "none",
        }}
      >
        <div className="row m-0 text-center text-white py-3">
          {/* Left Column: Developed By */}
          <div className="col-12 col-md-4 my-auto">
            <h6 style={{ color: "white" }}>Desarrollado por</h6>
            <h4 style={{ color: "white" }}>Data Pulse Analytics</h4>
            <hr
              style={{
                height: "0.3vh",
                margin: "auto",
                width: "50%",
                backgroundColor: "white",
                border: "none",
              }}
            />
          </div>

          {/* Middle Column: Rights Reserved */}
          <div className="col-12 col-md-4 my-auto">
            <h6 style={{ color: "white" }}>Derechos reservados</h6>
            <h4 style={{ color: "white" }}>GlobX Options & Futures</h4>
            <hr
              style={{
                height: "0.3vh",
                margin: "auto",
                width: "50%",
                backgroundColor: "white",
                border: "none",
              }}
            />
          </div>

          {/* Right Column: Social Links */}
          <div className="col-12 col-md-4 my-auto text-center">
            <h6 style={{ color: "white" }}>Art Design by</h6>
            <img
              src={Vlanc_logo}
              alt="Vlanc Logo"
              style={{
                height: "2em",
                marginTop: "10px",
              }}
            />
            <hr
              style={{
                height: "0.3vh",
                margin: "auto",
                width: "50%",
                backgroundColor: "white",
                border: "none",
              }}
            />
          </div>
        </div>
      </footer>
    </>
  );
}
