import React, { useState, useEffect } from "react";
import "./calculadoraPlazoEfectoModal.css";
import { Select, Button } from "antd";
import products_data from "../../../data/products.json";

const products = products_data.products;

const CalculatorModal = ({ onClose }) => {
  const [product, setProduct] = useState("Producto");
  const [product_name, setProductName] = useState("");
  const [volatility_input, setVolatilityInput] = useState(30);
  const [highlighted_template, setHighlightedTemplate] = useState(null);
  const [effect_time_input, setEffectTimeInput] = useState({
    Horas: 0,
    Minutos: 0,
    Días: 0,
  });
  const [trade_time, setTradeTime] = useState("");
  const parsed_trade_time = Number(trade_time.split(" ")[0]);
  const [calculate_clicked, setCalculateClicked] = useState(false);

  // Function to determine the recommended template
  const getRecommendedTemplate = (value) => {
    if (value <= 16) return "5 Vprom";
    if (value <= 22) return "10 Vprom";
    if (value <= 30) return "15 Vprom";
    if (value <= 48) return "25 Vprom";
    if (value <= 60) return "40 Vprom";
    return "65 Vprom";
  };

  // Update highlighted header based on the initial value of volatility_input
  useEffect(() => {
    setHighlightedTemplate(
      volatility_input <= 16
        ? "5"
        : volatility_input <= 22
        ? "10"
        : volatility_input <= 30
        ? "15"
        : volatility_input <= 48
        ? "25"
        : volatility_input <= 60
        ? "40"
        : "65"
    );
  }, [volatility_input]);

  // Handle changes in the volatility input and apply highlight logic
  const handleVolatilityInput = (e) => {
    const value = Math.max(0, Math.min(100, Number(e.target.value)));
    setVolatilityInput(value);

    setHighlightedTemplate(
      value <= 16
        ? "5"
        : value <= 22
        ? "10"
        : value <= 30
        ? "15"
        : value <= 48
        ? "25"
        : value <= 60
        ? "40"
        : "65"
    );
  };

  const handleProducts = (value) => {
    setProductName(value);
    const selected_product = products.find((e) => e.label === value);
    if (selected_product) {
      setProduct(selected_product.value);
      setTradeTime(selected_product.tradeTime);
      setEffectTimeInput({ Horas: 0, Minutos: 0, Días: 0 });
      setCalculateClicked(false);
    }
  };

  const handleEffectTimeInput = (unit, value) => {
    setEffectTimeInput((prev) => ({
      ...prev,
      [unit]: Math.max(0, Number(value)),
    }));
  };

  // Function to calculate table cell values based on unit type
  const calculateEffectValue = (unit, headerValue) => {
    if (!calculate_clicked) return 0;
    const timeInput = effect_time_input[unit];
    const tradeTime = parseFloat(parsed_trade_time);

    if (unit === "Horas") {
      return ((timeInput * headerValue) / tradeTime).toFixed(0);
    } else if (unit === "Minutos") {
      return ((((timeInput / 60) * headerValue) / tradeTime) * 24).toFixed(0);
    } else if (unit === "Días") {
      return ((timeInput * tradeTime * headerValue) / tradeTime).toFixed(0);
    }
    return "N/A";
  };

  const handleCalculate = () => {
    setCalculateClicked(true);
  };

  return (
    <div className="container_calculadoraPlazo">
      <header className="calculator-header">
        <h2>CALCULADORA DE PLAZO EFECTO</h2>
        <button className="close-button" onClick={onClose}>
          X
        </button>
      </header>
      <div>
        <div className="main-content-info">
          <div className="main-content-left-info">
            <div className="info-section title">
              <strong>GLOBX OPTIONS AND FUTURES</strong>
            </div>
            <div className="info-section">
              <strong>Descripción:</strong>
              <p>
                En esta calculadora se calculan los plazos efectos de un trade
                con todos los templates que tiene GLOBX. Sólo se debe colocar el
                valor de la X a usar (Temporalidad de la vela), el activo que se
                desea operar y la volatilidad actual del activo en las celdas
                coloreadas de amarillo. La calculadora te arroja el plazo efecto
                de cada template para que elijas el que va más acorde a la
                volatilidad del activo.
              </p>
            </div>
            <div className="info-section note">
              <strong>Nota:</strong>
              <p>
                SE CALCULA EL PLAZO EFECTO DE UNA X Y TE ARROJA EL RESPECTIVO
                PLAZO EFECTO ACORDE A LOS TEMPLATES
              </p>
            </div>
          </div>
          <div className="main-content-right-info">
            <div className="volatility-notes">
              <strong>Nota:</strong>
              <ol>
                <li>
                  Las celdas de color azul con Prom 15 y 25, son las más
                  utilizadas.
                </li>
                <li>
                  Para volatilidades menores o iguales a 16, se recomienda Prom
                  5.
                </li>
                <li>
                  Para volatilidades menores o iguales a 22, se recomienda Prom
                  10.
                </li>
                <li>
                  Para volatilidades menores o iguales a 30, se recomienda Prom
                  15.
                </li>
                <li>
                  Para volatilidades menores o iguales a 48, se recomienda Prom
                  25.
                </li>
                <li>
                  Para volatilidades menores o iguales a 60, se recomienda Prom
                  40.
                </li>
                <li>
                  Para volatilidades iguales o mayores a 61, se recomienda Prom
                  65.
                </li>
              </ol>
            </div>
          </div>
        </div>
        <div className="main-content">
          <div className="main-content-left">
            <div className="dropdown-section">
              <label
                style={{
                  marginLeft: "5px",
                  marginBottom: "5px",
                  fontWeight: "650",
                }}
              >
                Producto de inversión
              </label>
              <Select
                showSearch={true}
                defaultValue={product}
                style={{ width: "30%" }}
                onChange={handleProducts}
              >
                {products.map((e, index) => (
                  <Select.Option key={index} value={e.label}>
                    {e.label}
                  </Select.Option>
                ))}
              </Select>
              <span
                style={{
                  fontSize: "13px",
                  marginLeft: "5px",
                  marginTop: "5px",
                }}
              >
                {trade_time != "" ? (
                  <strong>Horario de Operación: {trade_time}</strong>
                ) : (
                  <strong>Horario de Operación Por definir</strong>
                )}
              </span>
            </div>

            <div className="table-section">
              {/* Tabla de plazo efecto */}
              {["Horas", "Minutos", "Días"].map((unit, index) => (
                <table key={unit} className="effect-time-table">
                  <thead>
                    <tr>
                      <th>Plazo efecto</th>
                      <th colSpan="6">Plantillas Vprom de GLOBX</th>
                    </tr>
                    <tr>
                      <th>X en {unit}</th>
                      {[5, 10, 15, 25, 40, 65].map((headerValue) => (
                        <th
                          key={headerValue}
                          style={{
                            backgroundColor:
                              highlighted_template === String(headerValue)
                                ? "green"
                                : "",
                          }}
                        >
                          {headerValue}
                        </th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        <input
                          type="number"
                          value={effect_time_input[unit]}
                          onChange={(e) =>
                            handleEffectTimeInput(unit, e.target.value)
                          }
                          style={{ width: "50px", textAlign: "center" }}
                        />{" "}
                        {unit.toLowerCase()}
                      </td>
                      {[5, 10, 15, 25, 40, 65].map((headerValue) => (
                        <td key={headerValue}>
                          {calculateEffectValue(unit, headerValue)}{" "}
                          {["Días", "Horas", "Días"][index].toLowerCase()}
                        </td>
                      ))}
                    </tr>
                  </tbody>
                </table>
              ))}
            </div>
          </div>
          <div className="main-content-right">
            <div className="table-section">
              {/* Recomendation Table */}
              <table className="recomendation-table">
                <thead>
                  <tr>
                    <th>Volatilidad del activo</th>
                    <th colSpan="3">Template recomendado por RD Y BOT FINN</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <input
                        type="number"
                        value={volatility_input}
                        min="0"
                        max="100"
                        onChange={handleVolatilityInput}
                        style={{ width: "50px", textAlign: "center" }}
                      />
                      {" " + "%"}
                    </td>
                    <td>{getRecommendedTemplate(volatility_input)}</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <Button
              className="calculate-button"
              type="primary"
              onClick={handleCalculate}
              style={{ marginTop: "10px" }}
            >
              Calcular
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CalculatorModal;
