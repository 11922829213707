import React, { useEffect, Suspense, useState } from "react";
import { Navigate } from "react-router-dom";
import axios from "axios";
import Loading from "../components/loading/Loading";
import fondo from "../images/FONDO.png";

//COMPONENTS
const Header = React.lazy(() => import("../components/common/Header"));
const Footer = React.lazy(() => import("../components/common/Footer"));
const Breadcrum = React.lazy(() => import("../components/common/Breadcrum"));
const Home = React.lazy(() => import("../components/home/Home"));

export default function HomePage() {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const urlParams = new URLSearchParams(window.location.search);
        const code = urlParams.get("code");
        const codeExpired = sessionStorage.getItem("codeExpire");

        // Handle token exchange and authentication status
        if (code && codeExpired == null) {
          sessionStorage.setItem("code", code);

          // Get Access Token
          const response = await axios.post("/api/exchange_code", { code });
          sessionStorage.setItem(
            "token",
            response.data["token_info"]["access_token"]
          );
          sessionStorage.setItem(
            "id_token",
            response.data["token_info"]["id_token"]
          );
          sessionStorage.setItem("codeExpire", true);
          setIsAuthenticated(true);
        } else {
          setIsAuthenticated(!!code);
        }
        // Fetch user info if authenticated
        const token = sessionStorage.getItem("token");
        // Get User Info
        const userInfoResponse = await axios.post("/api/get_user_info", {
          access_token: token,
        });
        const username = userInfoResponse.data["UserAttributes"].find(
          (e) => e.Name === "profile"
        )?.Value;
        sessionStorage.setItem("username", username);
      } catch (error) {
        console.log("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  // Loading Method
  if (loading) {
    return <Loading />;
  }

  // If is not Athenticate redirect to Login
  if (!isAuthenticated) {
    return <Navigate to="/" />;
  }

  return (
    <div
      style={{
        backgroundImage: `url(${fondo})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        minHeight: "100vh",
        display: "flex",
        flexDirection: "column",
        overflow: "hidden",
        width: "100vw",
      }}
    >
      <Suspense fallback={<Loading />}>
        <Header />
        <Breadcrum
          individualAnalysis="none"
          summaryAnalysis="none"
          downloadReports="none"
        />
        <div
          style={{
            flex: 1,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            overflow: "hidden",
          }}
        >
          <Home />
        </div>
        <Footer />
      </Suspense>
    </div>
  );
}
