import React, { Suspense } from "react";
import { Navigate } from "react-router-dom";
import Loading from "../components/loading/Loading";
import fondo from "../images/FONDO.png";

// Importación perezosa (lazy) de los componentes
const Header = React.lazy(() => import("../components/common/Header"));
const Footer = React.lazy(() => import("../components/common/Footer"));
const BreadcrumbH = React.lazy(() =>
  import("../components/common/BreadcrumbH")
);
const HistoricTable = React.lazy(() =>
  import("../components/historicTable/HistoricTable")
);

//TODO Review if this page works properly or not
export default function HistoricTablePage() {
  //Auth and Loading variables
  const isAuthenticated = sessionStorage.getItem("token");

  // If not authenticated, redirect to Login
  if (!isAuthenticated) {
    return <Navigate to="/" />;
  }

  return (
    <div
      style={{
        backgroundImage: `url(${fondo})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        minHeight: "100vh",
        display: "flex",
        flexDirection: "column",
        overflow: "hidden",
        width: "100%",
      }}
    >
      <Suspense fallback={<Loading />}>
        <div id="historic_page_container">
          <Header />
          <BreadcrumbH />
          <div
            style={{
              flex: 1,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              padding: "0 1rem",
            }}
          >
            <HistoricTable />
          </div>
          <Footer />
        </div>
      </Suspense>
    </div>
  );
}
