import React, { Suspense } from "react";
import { Navigate } from "react-router-dom";

import Footer from "../components/common/Footer";
import Header from "../components/common/Header";
import BreadcrumbD from "../components/common/BreadcrumbD";
import Analysis from "../components/individualAnalysis/Analysis";
import Loading from "../components/loading/Loading";
import fondo from "../images/FONDO.png";

export default function AnalysisPage() {
  //Auth and Loading variables
  const isAuthenticated = sessionStorage.getItem("token");

  // If is not Athenticate redirect to Login
  if (!isAuthenticated) {
    return <Navigate to="/" />;
  }

  return (
    <div
      style={{
        backgroundImage: `url(${fondo})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        minHeight: "100vh",
        display: "flex",
        flexDirection: "column",
        overflowX: "auto",
        overflowY: "hidden",
        width: "100%",
      }}
    >
      <Suspense fallback={<Loading />}>
        <Header />
        <BreadcrumbD />
        <div
          style={{
            flex: 1,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Analysis />
        </div>
        <Footer />
      </Suspense>
    </div>
  );
}
