import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { FaCalculator } from "react-icons/fa";
import { FaSignOutAlt } from "react-icons/fa";
import { FaQuestionCircle } from "react-icons/fa";

//Components
import CalculatorModal from "../common/modals/calculadoraPlazoEfectoModal";
import CustomTooltip from "../common/CustomTooltip";

//Images
import GlobxPinguino from "../../images/LOGOGX_pinguino.png";

// DATA FILES
import tooltips_text from "../../data/tooltips_text.json";

export default function Header() {
  const username = sessionStorage.getItem("username");
  const [isCalculatorOpen, setCalculatorOpen] = useState(false);

  //Get code
  const code = sessionStorage.getItem("code");

  const navigate = useNavigate();

  const Logout = (e) => {
    e.preventDefault();
    window.location.href = `${process.env.BASE_URL}`;
    sessionStorage.removeItem("token");
    sessionStorage.removeItem("code");
    sessionStorage.removeItem("codeExpire");
    sessionStorage.removeItem("username");
    sessionStorage.removeItem("id_token");
  };

  const toggleCalculator = () => {
    setCalculatorOpen(!isCalculatorOpen);
  };

  return (
    <>
      {/* ROW START*/}
      <div className="row m-0 align-items-center">
        {/* Logo Section 4 Columns*/}
        <div className="col-4 col-md-4 text-md-start my-2 my-md-0">
          <nav className="navbar navbar-light">
            <div className="d-flex align-items-center">
              <img
                src={GlobxPinguino}
                alt="logo"
                style={{ width: "70px", height: "70px", marginRight: "12px" }}
              />
              <h5
                className="text-md-start mt-2 mt-md-0"
                style={{
                  color: "#ffffff",
                  fontWeight: "bold",
                  fontSize: "3rem",
                  margin: 0,
                }}
              >
                Globx
              </h5>
            </div>
          </nav>
        </div>

        {/* Items section 8 Columns*/}
        <div className="col-8 col-md-8 text-end">
          <span
            className="text-md-start mt-2 mt-md-0"
            style={{
              color: "#ffffff",
              fontWeight: "bold",
              fontSize: "2rem",
              margin: 0,
              marginRight: "2vh",
            }}
          >
            {username}
          </span>

          <CustomTooltip
            text={tooltips_text.calculadora_plazo_efecto}
            placement="left"
          >
            <span>
              <FaCalculator
                onClick={toggleCalculator}
                style={{
                  cursor: "pointer",
                  fontSize: "1.5rem",
                  color: "white",
                  marginRight: "2vh",
                }}
              />
            </span>
          </CustomTooltip>

          <CustomTooltip text={tooltips_text.faqs} placement="left">
            <span>
              <FaQuestionCircle
                onClick={() => navigate(`/faqs/?code=${code}`)}
                style={{
                  cursor: "pointer",
                  fontSize: "2rem",
                  color: "white",
                  marginRight: "2vh",
                }}
              />
            </span>
          </CustomTooltip>

          <button
            type="button"
            className="btn btn-secondary btn-md"
            style={{ background: "none", color: "white", border: "none" }}
            onClick={Logout}
          >
            <FaSignOutAlt
              style={{
                cursor: "pointer",
                fontSize: "2rem",
                color: "white",
                marginRight: "2vh",
              }}
            />
          </button>
        </div>
      </div>
      {isCalculatorOpen && <CalculatorModal onClose={toggleCalculator} />}
    </>
  );
}
