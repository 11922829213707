import React, { Suspense } from "react";
import { Navigate } from "react-router-dom";
import fondo from "../images/FONDO.png";
import Loading from "../components/loading/Loading";

// Importación perezosa (lazy) de los componentes
const Header = React.lazy(() => import("../components/common/Header"));
const Footer = React.lazy(() => import("../components/common/Footer"));
const Breadcrum = React.lazy(() => import("../components/common/Breadcrum"));
const IndividualAnalysis = React.lazy(() =>
  import("../components/individualAnalysis/IndividualAnalysis")
);

export default function IndividualAnalysisPage() {
  //Auth and Loading variables
  const isAuthenticated = sessionStorage.getItem("token");

  // If not authenticated, redirect to Login
  if (!isAuthenticated) {
    return <Navigate to="/" />;
  }

  return (
    <div
      style={{
        backgroundImage: `url(${fondo})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        minHeight: "100vh",
        display: "flex",
        flexDirection: "column",
        overflow: "hidden",
        width: "100%",
      }}
    >
      <Suspense fallback={<Loading />}>
        <Header />
        <Breadcrum
          individualAnalysis="show"
          summaryAnalysis="none"
          downloadReport="none"
        />
        <div
          style={{
            flex: 1,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            padding: "0 1rem",
          }}
        >
          <IndividualAnalysis />
        </div>
        <Footer />
      </Suspense>
    </div>
  );
}
