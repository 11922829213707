import React, { Suspense } from "react";
import { Navigate } from "react-router-dom";

import CorrelationStudy from "../components/correlationStudy/CorrelationStudy";
import Header from "../components/common/Header";
import Footer from "../components/common/Footer";

import BreadcrumbC from "../components/common/BreadcrumbC";
import Loading from "../components/loading/Loading";
import fondo from "../images/FONDO.png";

export default function CorrelationStudyPage() {
  //Auth and Loading variables
  const isAuthenticated = sessionStorage.getItem("token");

  // If not authenticated, redirect to Login
  if (!isAuthenticated) {
    return <Navigate to="/" />;
  }

  return (
    <div
      style={{
        backgroundImage: `url(${fondo})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        minHeight: "100vh",
        display: "flex",
        flexDirection: "column",
        overflowX: "auto",
        overflowY: "hidden",
        width: "100%",
      }}
    >
      <Suspense fallback={<Loading />}>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            minHeight: "100vh",
          }}
        >
          <Header />
          <BreadcrumbC />
          <div
            style={{
              flex: 1,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              overflow: "hidden",
            }}
          >
            <CorrelationStudy />
          </div>
          <Footer />
        </div>
      </Suspense>
    </div>
  );
}
