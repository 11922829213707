import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import Card from "react-bootstrap/Card";
import { Modal, Button } from "react-bootstrap";

import Individual_checklist_icone from "../../images/Individual_checklist_icone.png";
import Group_checklist_icone from "../../images/Group_checklist_icone.png";

import MyVerticallyCenteredModal from "../common/modals/confirmationModal";
import "../positionRegister/PositionRegister.css";

//GIFS
import GIF from "../common/Gif";
import phrases_list from "../../data/phrases.json";
import recuerda_gif from "../../gifs/RECUERDA_GIFF_VIDEO.gif";
import sabias_gif from "../../gifs/SABIAS-QUE_GIFF_VIDEO.gif";

export default function analysis() {
  //Get code
  const code = sessionStorage.getItem("code");

  // GIF Phrases
  const [randomPhrase, setRandomPhrase] = useState("");
  const [showRecuerda, setShowRecuerda] = useState(true);
  const [showSabias, setShowSabias] = useState(false);

  const [show, setShow] = useState(false);

  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);

  // GIF Recuerda Timer
  useEffect(() => {
    if (showRecuerda) {
      const randomIndex = Math.floor(
        Math.random() * phrases_list.recuerda_phrases.length
      );
      setRandomPhrase(phrases_list.recuerda_phrases[randomIndex]);

      const recuerdaTimer = setTimeout(() => {
        setShowRecuerda(false);
        setTimeout(() => {
          setShowSabias(true);
        }, 120000);
      }, 18000);

      return () => clearTimeout(recuerdaTimer);
    }
  }, [showRecuerda]);

  // GIF Sabias Timer
  useEffect(() => {
    if (showSabias) {
      const randomIndex = Math.floor(
        Math.random() * phrases_list.sabias_phrases.length
      );
      setRandomPhrase(phrases_list.sabias_phrases[randomIndex]);

      const sabiasTimer = setTimeout(() => {
        setShowSabias(false);
        setTimeout(() => {
          const showNext =
            Math.random() < 0.5 ? setShowRecuerda(true) : setShowSabias(true);
        }, 120000);
      }, 18000);

      return () => clearTimeout(sabiasTimer);
    }
  }, [showSabias]);

  const navigate = useNavigate();
  const [modalShow, setModalShow] = React.useState(false);

  return (
    <>
      <div className="container">
        <div className="text-center mb-5">
          <h1 style={{ fontWeight: "bold", color: "white" }}>
            Tipo de Checklist
          </h1>
        </div>
        <div className="row justify-content-center">
          {/* Checklist Individual Card */}
          <div className="col-12 col-md-5 mb-4">
            <Card
              onClick={() => navigate(`/individualAnalysis/?code=${code}`)}
              style={{
                cursor: "pointer",
                background:
                  "linear-gradient(to bottom, rgba(0, 139, 139), rgba(0, 0, 0, 0))",
                border: "none",
                borderRadius: "100px 100px 0 0",
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "300px",
                  background: "none",
                }}
              >
                <Card.Img
                  src={Individual_checklist_icone}
                  style={{
                    width: "200px",
                    height: "200px",
                    objectFit: "cover",
                  }}
                />
              </div>
              <Card.Body
                style={{
                  color: "white",
                  background: "none",
                  padding: "60px",
                  borderRadius: "10px",
                  maxWidth: "100%",
                  minHeight: "300px",
                  textAlign: "center",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  border: "none",
                }}
              >
                <Card.Title
                  className="text-center"
                  style={{
                    fontSize: "2.8rem",
                    margin: "0",
                    paddingTop: "10px",
                    position: "relative",
                    top: "-50px",
                  }}
                >
                  Checklist individual
                </Card.Title>
                <Card.Text
                  className="text-center"
                  style={{
                    fontSize: "1.3rem",
                    margin: "0",
                    paddingTop: "10px",
                    position: "relative",
                  }}
                >
                  Esta herramienta se utiliza para evaluar la tendencia o trade
                  que se desea realizar. Analiza detalladamente todos los
                  indicadores y asigna una calificación. Si la calificación es
                  favorable, significa que la tendencia es adecuada.
                </Card.Text>
              </Card.Body>
            </Card>
          </div>
          <div className="col-2"></div>

          {/* Checklist Grupal Card */}
          <div className="col-12 col-md-5 mb-4">
            <Card
              //onClick={() => navigate(`/groupAnalysis/?code=${code}`)}
              onClick={handleShow}
              style={{
                cursor: "pointer",
                background:
                  "linear-gradient(to bottom, rgba(0, 139, 139), rgba(0, 0, 0, 0))",
                border: "none",
                borderRadius: "100px 100px 0 0",
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "300px",
                  background: "none",
                }}
              >
                <Card.Img
                  src={Group_checklist_icone}
                  style={{
                    width: "200px",
                    height: "200px",
                    objectFit: "cover",
                  }}
                />
              </div>
              <Card.Body
                style={{
                  color: "white",
                  background: "none",
                  padding: "60px",
                  borderRadius: "10px",
                  maxWidth: "100%",
                  minHeight: "300px",
                  textAlign: "center",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  border: "none",
                }}
              >
                <Card.Title
                  className="text-center"
                  style={{
                    fontSize: "2.8rem",
                    margin: "0",
                    paddingTop: "10px",
                    position: "relative",
                    top: "-50px",
                  }}
                >
                  Checklist grupal
                </Card.Title>
                <Card.Text
                  className="text-center"
                  style={{
                    fontSize: "1.3rem",
                    margin: "0",
                    paddingTop: "10px",
                    position: "relative",
                  }}
                >
                  Se utiliza para evaluar varios productos simultáneamente, con
                  un máximo de 8 productos a la vez. Esto te ayudará a evitar
                  analizar producto por producto y a ser más eficiente en tus
                  tiempos de operación.
                </Card.Text>
              </Card.Body>
            </Card>
          </div>
        </div>
      </div>
      <Modal show={show} onHide={handleClose} centered>
        <Modal.Header closeButton>
          <Modal.Title>Componente en edición</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Este componente está actualmente en edición. En cuanto se libere les
          notificaremos.
        </Modal.Body>
        <Modal.Footer>
          {/* Texto alineado a la izquierda */}
          <div className="me-auto"> Atte: Data Pulse Analytics</div>

          {/* Botón manteniéndose en su posición */}
          <Button variant="secondary" onClick={handleClose}>
            Cerrar
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Modal and Notifications */}
      <MyVerticallyCenteredModal
        show={modalShow}
        onHide={() => setModalShow(false)}
      />

      {showRecuerda && (
        <div
          className="gif-notification-box"
          style={{
            background:
              "linear-gradient(to bottom, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0))",
            border: "none",
            minWidth: "400px",
          }}
        >
          <button
            onClick={() => setShowRecuerda(false)}
            style={{
              position: "absolute",
              top: "10px",
              right: "10px",
              background: "transparent",
              border: "none",
              fontSize: "18px",
              color: "white",
              cursor: "pointer",
            }}
          >
            &times;
          </button>
          <GIF gif={recuerda_gif} width={"150px"} />
          <p style={{ fontSize: "14px", color: "white", margin: 0 }}>
            {randomPhrase}
          </p>
        </div>
      )}
      {showSabias && (
        <div
          className="gif-notification-box"
          style={{
            background:
              "linear-gradient(to bottom, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0))",
            border: "none",
            minWidth: "400px",
          }}
        >
          <button
            onClick={() => setShowSabias(false)}
            style={{
              position: "absolute",
              top: "10px",
              right: "10px",
              background: "transparent",
              border: "none",
              color: "white",
              fontSize: "18px",
              cursor: "pointer",
            }}
          >
            &times;
          </button>
          <GIF gif={sabias_gif} width={"150px"} />
          <p style={{ fontSize: "14px", color: "white", margin: 0 }}>
            {randomPhrase}
          </p>
        </div>
      )}
    </>
  );
}
