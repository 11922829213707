import React, { Suspense } from "react";
import { Navigate } from "react-router-dom";
import Header from "../components/common/Header";
import Footer from "../components/common/Footer";
import Breadcrum from "../components/common/Breadcrum";
import fondo from "../images/FONDO.png";
import Loading from "../components/loading/Loading";

const AnalysisSumary = React.lazy(() =>
  import("../components/analysisSummary/AnalysisSummary")
);

export default function AnalysisSummaryPage() {
  //Auth and Loading variables
  const isAuthenticated = sessionStorage.getItem("token");

  // If is not Athenticate redirect to Login
  if (!isAuthenticated) {
    return <Navigate to="/" />;
  }

  return (
    <div
      style={{
        backgroundImage: `url(${fondo})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        minHeight: "100vh",
        display: "flex",
        flexDirection: "column",
        overflowX: "auto",
        overflowY: "hidden",
        width: "100%",
      }}
    >
      <Suspense fallback={<Loading />}>
        <Header />
        <Breadcrum AnalysisSumary="show" individualAnalysis="none" />
        <div
          style={{
            flex: 1,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            padding: "0 1rem",
          }}
        >
          <AnalysisSumary />
        </div>
        <Footer />
      </Suspense>
    </div>
  );
}
